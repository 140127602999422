import SeguroBiciLogo from "../assets/iconos/segurobici.svg";
import SeguroMotosLogo from "../assets/iconos/seguromotos.svg";
import SeguroMascotasLogo from "../assets/iconos/seguromascotas.svg";

export const landingsTabs = [
    {
      name: "Seguro Bici",
      id_landing: 1,
      initialProduct: 1
    },
    {
      name: "Seguro Motos",
      id_landing: 2,
      initialProduct: 5
    }
];

export const PREMIOS_TABS = [
  {
    name: "Vendedores",
  },
  {
    name: "Grupos",
  },
  {
    name: "Estadísticas",
  },
  {
    name: "Objetivos",
  },
  {
    name: "Historial",
  },
];

export const VENDEDOR_TABS = [
  {
    name: "Detalle",
  },
  {
    name: "Operaciones",
  },
  {
    name: "Rendimiento anual",
  },
  {
    name: "Rendimiento diario",
  },
  {
    name: "Edición de premios",
  },
];

export const SINIESTRO_TABS = [
  {
    name: "Información",
  },
  {
    name: "Documentación",
  },
];


export const EMISION_TABS = [
  {
    name: "Solicitud",
  },
  {
    name: "Historial",
  },
  {
    name: "Documentación",
  },
];