import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../../constants";

export const Container = styled.div`
  width: 900px;
  padding: 40px 70px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 700px;
  background: #fff;
  font-family: "DM Sans", sans-serif;
  border-radius: 6px;
  gap: 10px;
  box-sizing: border-box;

  .asignacion-header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    h2 {
      color: ${leerColor(colores.negro)};
      font-size: 24px;
      margin: 0;
    }

    p {
      color: ${leerColor(colores.grisEstados)};
      margin: 0;
      font-size: 14px;
    }
  }

  .icon-sin-solicitudes {
    font-size: 60px;
    color: ${leerColor(colores.azulRebranding)};
    display: flex;
  }

  .sin-solicitudes-container {
    display: flex;
    flex-direction: column;
    margin: 0;
    justify-content: center;
    align-items: center;
    gap: 10px;

    h2 {
      color: ${leerColor(colores.negro)} !important;
      font-weight: 500;
      margin: 0;
    }

    .description {
      color: ${leerColor(colores.grisEstados)};
      font-size: 14px;
    }
  }

  h3 {
    font-weight: 500;
  }

  .operador {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    transition: 0.4s;
    height: 100%;

    .icon {
      font-size: 24px;
    }

    h3 {
      font-size: 14px;
      margin: 0;
      font-weight: 500;
      color: ${leerColor(colores.grisEstados)};
    }

    .asignadas {
      font-size: 12px;
      color: ${leerColor(colores.grisEstados)};
    }

    :hover {
      border: 1px solid ${leerColor(colores.celeste)};
    }
  }

  .operador-seleccionado {
    border: 1px solid ${leerColor(colores.celeste)};

    h3 {
      font-weight: 600;
      color: ${leerColor(colores.celeste)};
    }

    .icon {
      color: ${leerColor(colores.celeste)};
    }
  }

  .botones-container {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }
`;

export const RechazoContainer = styled.div`
  background-color: #fff;
  box-sizing: border-box;
  width: 700px;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
  .sin-solicitudes-container {
    display: flex;
    flex-direction: column;
    margin: 0;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 40px 0;

    h2 {
      color: ${leerColor(colores.negro)} !important;
      font-weight: 500;
      margin: 0;
    }

    .description {
      color: ${leerColor(colores.grisEstados)};
      font-size: 14px;
    }

    .icon-sin-solicitudes {
      font-size: 60px;
      color: ${leerColor(colores.azulRebranding)};
      display: flex;
    }
  }

  .operador {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    transition: 0.4s;
    height: 100%;
    min-height: 116px;

    .icon {
      font-size: 24px;
    }

    h4 {
      font-size: 14px;
      margin: 0;
      font-weight: 400;
      color: ${leerColor(colores.grisEstados)};
    }

    .asignadas {
      font-size: 12px;
      color: ${leerColor(colores.grisEstados)};
    }

    :hover {
      opacity: 0.5;
    }
  }

  .operador-seleccionado {
    border: 1px solid ${leerColor(colores.azulRebranding)};

    h4 {
      font-weight: 600;
      color: ${leerColor(colores.azulRebranding)};
    }

    .icon {
      color: ${leerColor(colores.azulRebranding)};
    }
  }

  .motivos-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    label {
      font-family: "DM Sans", sans-serif;
      font-size: 14px;
      color: #202020;
      font-weight: 500;
    }
    .motivo {
      font-size: 14px;
      color: ${leerColor(colores.grisEstados)};
      border: 1px solid #eaeaea;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 20px;
      width: 100%;
      cursor: pointer;
      transition: 0.2s;
    }

    .motivo-selected {
      color: ${leerColor(colores.azulRebranding)};
      border: 1px solid ${leerColor(colores.azulRebranding)};
      font-weight: 500;
    }

    .motivo:hover {
      opacity: 0.8s;
    }
  }
  .header-container {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    //margin: 20px 0 20px 0;
  }
  textarea {
    margin: 0 !important;
  }
  p {
    margin: 0;
    color: ${leerColor(colores.grisEstados)};
    font-size: 14px;
  }
  .data-container {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;

    h3 {
      margin: 0;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .solicitudes-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-height: 380px;
    overflow-y: scroll;
  }
  .buttonsCont {
    display: flex;
    width: 100%;
    padding: 0 30px;
    gap: 10px;
  }
  a {
    width: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-right: 0;
  }
  /*
  .buttonsCont a {
    margin-left: 32px;
  }
  */
  .actionButtonsCont {
    display: flex;
    justify-content: flex-end;
    //margin-top: 43px;
  }
  .selectCont {
    width: 50%;
  }
`;

export const OperadorItem = styled.div`
  display: flex;
  width: 100%;
  //border-bottom:1px solid #eeeeee;
  align-items: center;
  justify-content: space-between;
  font-family: "DM Sans", sans-serif;
  //padding: 30px 0;

  :first-of-type {
    padding-top: 0;
  }

  .asegurado {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    color: ${leerColor(colores.negro)};
  }

  .solicitud-data-container {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    box-sizing: border-box;
    height: 90px;
    justify-content: space-between;
  }

  span {
    font-size: 14px;
    color: ${leerColor(colores.grisEstados)};
  }

  .solicitud {
    display: flex;
    gap: 16px;
    align-items: flex-start;

    .logo-container {
      width: 90px;
      height: 90px;
      border-radius: 6px;
      border: 1px solid #eaeaea;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      span {
        font-size: 12px;
      }
    }

    img {
      width: 75px;
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
`;
