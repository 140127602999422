import { SolicitudProvider } from "../../../context/SolicitudContext"
import Wizard from "../../Wizard"
import AseguradoForm from "../AseguradoForm"
import BienAseguradoForm from "../BienAseguradoForm"
import EnviarSolicitud from "../EnviarSolicitud"
import ObservacionForm from "../ObservacionForm"
import PagoForm from "../PagoForm"
import SeguroForm from "../SeguroForm"


const NuevaSolicitudForm = ({ solicitud, selectsData }) => {
  return (
    <SolicitudProvider solicitud={solicitud} selectsData={selectsData}>
      <Wizard> 
          <AseguradoForm />
          <SeguroForm />
          <BienAseguradoForm />
          <PagoForm />
          <ObservacionForm />
          <EnviarSolicitud />

      </Wizard>
  </SolicitudProvider>
  )
}

export default NuevaSolicitudForm