import "../../index.css";
import GlobalStyle from "../../constants/globalStyle.js";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import {
  Estadisticas,
  Rechazos,
  Fichajes,
  FedPat,
  Campanias,
  Capacitaciones,
  StorageApi,
  Objetivos,
  Cobranzas,
  EstadisticasCobranzas,
  ObjetivosCobranzas,
  PremiosVendedores,
  ObjetivosPorVendedor,
  Colaboradores,
} from "../../pages/index";
import CargarSolicitudEmision from "../../pages/new/CargaSolicitudEmision";
import EmisionRefactor from "../../pages/new/EmisionRefactor";
import EditarSolicitudEmision from "../../pages/new/EditarSolicitudEmision";
import SolicitudEmisionRefactor from "../../pages/new/SolicitudEmisionRefactor";
import EmisionesRefactor from "../../pages/new/EmisionesRefactor";
import HistorialSolicitudesRefactor from "../../pages/new/HistorialSolicitudes";
import EstadisticasProductoCanalRefactor from "../../pages/new/EstadisticasProductoCanal";
import AutogestionRefactor from "../../pages/new/AutogestionRefactor";

import { AuthProvider } from "../../context/AuthContext";
import UserIdle from "../UserIdle/index";
import UsuariosRefactor from "../../pages/new/UsuariosRefactor";
import LoginRefactor from "../../pages/new/LoginRefactor";
import ObjetivosLeads from "../../pages/new/ObjetivosLeads";
import SiniestrosRefactor from "../../pages/new/SiniestrosRefactor";
import LandingsRefactor from "../../pages/new/LandingsRefactor/index.js";
import LandingsPlan from "../../pages/new/LandingsPlan/index.js";
import LandingsCrearPlan from "../../pages/new/LandingsCrearPlan/index.js";

import { SocketProvider } from "../../context/SocketProvider.js";
import { NotificacionesProvider } from "../../context/NotificacionesProvider.js";
import Premios from "../../pages/new/Premios/index.jsx";
import PremiosCrearGrupo from "../../pages/new/PremiosCrearGrupo/index.jsx";
import Premio from "../../pages/new/Premio/index.jsx";
import Vendedor from "../../pages/new/Vendedor/index.js";
import GrupoVendedores from "../../pages/new/Grupo/index.jsx";
import PremiosEditarGrupo from "../../pages/new/PremiosEditarGrupo/index.js";
import CrearUsuario from "../../pages/new/CrearUsuario/index.js";
import EditarUsuario from "../../pages/new/EditarUsuario/index.js";
import CrearSiniestro from "../../pages/new/CrearSiniestro/index.js";
import Siniestro from "../../pages/new/Siniestro/index.js";
import ContratacionAutogestion from "../../pages/new/ContratacionAutogestion/index.js";
import RechazosV2 from "../../pages/new/RechazosV2";
import RechazoV2 from "../../pages/new/Rechazo/index.js";
import GestionRechazos from "../../pages/new/RechazosGestion/index.js";
import NotificacionRechazos from "../../pages/new/RechazosNotificacion/index.js";
import NewLayout from "../../pages/new/newlayout.js";
import NewPremios from "../../pages/new/newPremios.js";
import FichajesRefactor from "../../pages/new/FichajesRefactor/index.js";
import CrearExcepcion from "../../pages/new/CrearExcepcion/index.js";
import NewEstadisticasCobranzas from "../../pages/new/EstadisticasCobranzas/index.js";
import { ThemeModeProvider } from "../../context/ThemeContext.js";
import NewEmision from "../../pages/new/NewEmision/index.js";

function App() {

  return (
    <>
      <AuthProvider>
        <SocketProvider>
          <NotificacionesProvider>
            <ThemeModeProvider>
            <Router>
              <Switch>
                <Route
                  path="/Premios/EditarGrupo/:id"
                  component={PremiosEditarGrupo}
                />
                <Route
                  path="/Premio/Vendedor/:id/:id_premio"
                  component={Premio}
                />
                <Route
                  path="/Premios/Vendedores/Grupos/:id"
                  component={GrupoVendedores}
                />

                <Route
                  path="/NewFichajes"
                  component={FichajesRefactor}
                />

                <Route
                  path="/Excepcion"
                  component={CrearExcepcion}
                />

                <Route
                  path="/NewLayout"
                  component={NewLayout}
                />

                <Route
                  path="/NewPremios"
                  component={NewPremios}
                />

                <Route path="/Siniestro/:id_siniestro" component={Siniestro} />
                <Route path="/Siniestros/CrearSiniestro" component={CrearSiniestro} />

                <Route path="/Autogestion/:id" component={ContratacionAutogestion} />

                <Route path="/Premios" component={Premios} />
                <Route path="/Premio/Vendedor/:id" component={Vendedor} />
                <Route path="/CrearGrupo" component={PremiosCrearGrupo} />
                <Route exact path="/StorageApi" component={StorageApi} />
                <Route exact path="/Rechazos" component={Rechazos} />
                <Route exact path="/Rechazos2" component={RechazosV2} />
                <Route exact path="/Rechazos2/gestionar" component={GestionRechazos} />
                <Route exact path="/Rechazos2/notificar" component={NotificacionRechazos} />
                <Route exact path="/FedPat" component={FedPat} />
                <Route exact path="/Campanias" component={Campanias} />
                <Route exact path="/Cobranzas" component={Cobranzas} />
                <Route exact path="/Fichajes" component={Fichajes} />
                <Route
                  exact
                  path="/Capacitaciones"
                  component={Capacitaciones}
                />
                <Route exact path="/Objetivos" component={Objetivos} />
                <Route path="/Estadisticas" component={Estadisticas} />

                <Route
                  path="/EstadisticasCobranzas"
                  component={NewEstadisticasCobranzas}
                />
                <Route
                  path="/ObjetivosCobranzas"
                  component={ObjetivosCobranzas}
                />
                <Route
                  path="/PremiosVendedores"
                  component={PremiosVendedores}
                />
                <Route
                  path="/ObjetivosPorVendedor"
                  component={ObjetivosPorVendedor}
                />

                {/* Refactor Test */}
                <Route
                  path="/CargarSolicitudEmision"
                  component={CargarSolicitudEmision}
                />
                <Route
                  path="/EditarSolicitudEmision/:id"
                  component={EditarSolicitudEmision}
                />
                <Route path="/NewEmision/:id" component={NewEmision} />
                <Route path="/Emision/:id" component={EmisionRefactor} />
                <Route path="/Rechazo/:id" component={RechazoV2} />
                <Route
                  path="/SolicitudEmision"
                  component={SolicitudEmisionRefactor}
                />
                <Route path="/Emisiones" component={EmisionesRefactor} />
                <Route
                  path="/HistorialSolicitudes"
                  component={HistorialSolicitudesRefactor}
                />
                <Route
                  path="/EstadisticasProductoCanal"
                  component={EstadisticasProductoCanalRefactor}
                />
                <Route path="/Usuarios/Editar/:id_operador" component={EditarUsuario} />
                <Route path="/Usuarios/Crear" component={CrearUsuario} />
                <Route path="/Usuarios" component={UsuariosRefactor} />

                <Route path="/Autogestion" component={AutogestionRefactor} />
                <Route path="/ObjetivosLeads" component={ObjetivosLeads} />
                <Route path="/Siniestros" component={SiniestrosRefactor} />
                <Route
                  path="/Landings/Plan/Crear"
                  component={LandingsCrearPlan}
                />
                <Route path="/Landings/Plan/:id" component={LandingsPlan} />
                <Route path="/Landings" component={LandingsRefactor} />
                <Route path="/Colaboradores" component={Colaboradores} />
                <Route path="/" component={LoginRefactor} />
              </Switch>
              <UserIdle></UserIdle>
            </Router>
            <GlobalStyle></GlobalStyle>
            </ThemeModeProvider>
          </NotificacionesProvider>
        </SocketProvider>
      </AuthProvider>
    </>
  );
}

export default App;
