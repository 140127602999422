import React, { useEffect, useState } from 'react'
import Subtitle from '../../../Subtitle'
import { FiBarChart } from 'react-icons/fi'
import { Col, Grid } from '../../../Grid'
import BarChart from '../../../BarChart'
import axiosRequest from '../../../../utils/axiosConfig'
import { useTheme } from '../../../../context/ThemeContext'

const VendedorRendimientoAnualTab = ({ data }) => {
  
  const { vendedorNombre, vendedor, mes, setLoading } = data 
  const [prima, setPrima] = useState(null);
  const [operaciones, setOperaciones] = useState(null);
  const { theme } = useTheme()
  
  useEffect(() => {
    const getRendimientoAnual = async () => {
      if (vendedor) {
        try {
          setLoading(true);                 
         const prima = await axiosRequest.get(`/premios/prima/${vendedor.id}`);
          const labels = [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
          ];
   
          const dataPrima = {
            labels,
            datasets: [
              {
                label: "Objetivo prima",
                data: prima.data.map((item) => item.objetivo_prima),
                backgroundColor: "#c4d4ff",
              },
              {
                label: "Prima obtenida",
                data: prima.data.map((item) => Math.round(item.prima)),
                backgroundColor: "#1A56FF",
              },
            ],
          };
          const dataOperaciones = {
            labels,
            datasets: [
              {
                label: "Objetivo operaciones",
                data: prima.data.map((item) => item.objetivo_operaciones),
                backgroundColor: "#c4d4ff",
              },
              {
                label: "Operaciones realizadas",
                data: prima.data.map((item) => item.operaciones),
                backgroundColor: "#1A56FF",
              },
            ],
          };

          setPrima(dataPrima);
          setOperaciones(dataOperaciones);
          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    };
    getRendimientoAnual();
  }, [vendedor, mes]);
  
  return (
    <>
    {prima ? (
      <>
        <Subtitle
          icon={<FiBarChart />}
          title={"Prima"}
          description={`Evolución de la prima obtenido por ${vendedorNombre} a lo largo del año`}
          disableDivider={true}
        />
        <Grid colGap={21} rowGap={21} narrow={false}>
          <Col desktop={12}>
            <BarChart data={prima} optionsData={{
                responsive: true,
                scales: {
                  x: {
                    stacked: false,
                    ticks: {
                      color: theme === "dark" ? "#ffffff" : '#666666',
                    },
                    grid: {
                      borderColor: theme === "dark" ? "#243158" : '#e5e5e5',
                      tickColor: theme === "dark" ? "#243158" : '#e5e5e5',
                      color: theme === "dark" ? "#243158" : '#e5e5e5',
                    }
                  },
                  y: {
                    stacked: false,
                    ticks: {
                      color: theme === "dark" ? "#ffffff" : '#666666',
                    },
                    grid: {
                      borderColor: theme === "dark" ? "#243158" : '#e5e5e5',
                      tickColor: theme === "dark" ? "#243158" : '#e5e5e5',
                      color: theme === "dark" ? "#243158" : '#e5e5e5',
                    }
                  },
                },
                plugins: {
                  title: {
                    display: true,
                    text: 'Visualización de primas realizadas a lo largo del año',
                  },
                },
              }} />
          </Col>
        </Grid>
      </>
    ) : null}
    <hr />
    {operaciones ? (
      <>
        <Subtitle
          icon={<FiBarChart />}
          title={"Operaciones"}
          description={`Evolución de la cantidad de operaciones realizadas por ${vendedorNombre} a lo largo del año`}
          disableDivider={true}
        />
        <Grid colGap={21} rowGap={21} narrow={false}>
          <Col desktop={12}>
            <BarChart data={operaciones} optionsData={{
                responsive: true,
                scales: {
                  x: {
                    stacked: false,
                    ticks: {
                      color: theme === "dark" ? "#ffffff" : '#666666',
                    },
                    grid: {
                      borderColor: theme === "dark" ? "#243158" : '#e5e5e5',
                      tickColor: theme === "dark" ? "#243158" : '#e5e5e5',
                      color: theme === "dark" ? "#243158" : '#e5e5e5',
                    }
                  },
                  y: {
                    stacked: false,
                    ticks: {
                      color: theme === "dark" ? "#ffffff" : '#666666',
                    },
                    grid: {
                      borderColor: theme === "dark" ? "#243158" : '#e5e5e5',
                      tickColor: theme === "dark" ? "#243158" : '#e5e5e5',
                      color: theme === "dark" ? "#243158" : '#e5e5e5',
                    }
                  },
                },
                plugins: {
                  title: {
                    display: true,
                    text: 'Visualización de primas realizadas a lo largo del año',
                  },
                },
              }} />
          </Col>
        </Grid>
      </>
    ) : null}
  </>
  )
}

export default VendedorRendimientoAnualTab