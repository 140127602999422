import Switch from "../../Switch";
import ButtonCalc from "../../ButtonCalc";
import { CalculoContainer } from "./styled";
import InputBasic from "../../InputBasic";
import ErrorInput from "../../ErrorInput";

const ValorCuotaForm = ({ form, valorCuota }) => {

  const { tieneValorCuota, handleValorCuota } = valorCuota 
  const { inputValues, handleChange, formErrors, handleChangeOperador } = form 
  
  return (
    <div>
      <div className="switch-calculo">
        <span>¿El plan tiene cálculo para el valor de la cuota?</span>
        <Switch checked={tieneValorCuota} onChange={handleValorCuota} />
      </div>
      {tieneValorCuota ? (
        <>
          <CalculoContainer>
            <div className="plan-cotizacion">
              <div className="plan-calculo">
                <span className="plan-valor-producto">Valor producto</span>
                {/* Primer valor */}
                <ButtonCalc
                  name="operador_uno"
                  value={inputValues.operador_uno}
                  handleChange={handleChangeOperador}
                />
                <div className="plan-input-container">
                  <InputBasic
                    type={"number"}
                    value={inputValues.valor_uno}
                    labelName="Primer valor"
                    name="valor_uno"
                    onChange={handleChange}
                    placeholder="3"
                  />
                </div>
                {/* Segundo valor */}
                <ButtonCalc
                  name="operador_dos"
                  value={inputValues.operador_dos}
                  handleChange={handleChangeOperador}
                />
                <div className="plan-input-container">
                  <InputBasic
                    type={"number"}
                    value={inputValues.valor_dos}
                    labelName="Segundo valor"
                    name="valor_dos"
                    onChange={handleChange}
                    placeholder="2"
                  />
                </div>
              </div>
            </div>
          </CalculoContainer>
          {formErrors.operador_uno && (
            <ErrorInput>{formErrors.operador_uno}</ErrorInput>
          )}
          {formErrors.operador_dos && (
            <ErrorInput>{formErrors.operador_dos}</ErrorInput>
          )}
          {formErrors.valor_uno && (
            <ErrorInput>{formErrors.valor_uno}</ErrorInput>
          )}
          {formErrors.valor_dos && (
            <ErrorInput>{formErrors.valor_dos}</ErrorInput>
          )}
        </>
      ) : null}
    </div>
  );
};

export default ValorCuotaForm;
