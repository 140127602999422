import { CoberturasContainer, Cobertura } from "./styled";
import Switch from "../../Switch";
import ErrorInput from "../../ErrorInput";

const CoberturasForm = ({ coberturas, form }) => {

  const { handleChangeCobertura, error } = form
  
  return (
    <div>
      <CoberturasContainer>
        {coberturas.length > 0 ? (
          coberturas.map((cobertura) => {
            return (
              <Cobertura>
                <span>{cobertura.nombre_completo}</span>
                <Switch
                  checked={cobertura.activo === 1 ? true : false}
                  name={cobertura.id_landing_cobertura}
                  onChange={handleChangeCobertura}
                />
              </Cobertura>
            );
          })
        ) : (
          <p>Seleccioná una landing para elegir las coberturas del plan</p>
        )}
      </CoberturasContainer>
      {error && (
        <ErrorInput>{error}</ErrorInput>
      )}
    </div>
  );
};

export default CoberturasForm;
