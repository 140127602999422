import { useState, useContext, createContext, useEffect } from "react";
import { useForm } from "../hooks/useForm";
import { datosAnulacionForm, datosEndosoForm, datosObservacionForm, datosSeguroForm } from "../utils/formInitialValues";
import { datosAnulaciónReglas, datosObservacionReglas, datosSeguroReglas, endosoFormReglas } from "../utils/formRules";
import { datosAseguradoForm } from "../utils/formInitialValues";
import { datosAseguradoReglas } from "../utils/formRules";
import { datosPagoForm } from "../utils/formInitialValues";
import { datosPagoReglas } from "../utils/formRules";
//import { datosBienAseguradoReglas } from "../utils/formRules";
import { validacionesBienAsegurado } from "../utils/formRules";
import { getCamposCobertura, getCoberturas, getCompanias, getEstadosCivil, getNacionalidades, getProductos, getProfesiones, getProvincias, getVigencias, postAseguradoData, postSolicitud, getLocalidades, actualizarSolicitud, eliminarAsegurado, eliminarPago, eliminarSeguro, eliminarBienAsegurado, eliminarSolicitud, getAseguradoByDNI, postAseguradoAdicionalData, getOperadores } from "../services/Emision";

import { useAsegurado } from "../hooks/useAsegurado";
import { useSeguro } from "../hooks/useSeguro";
import { useBienAsegurado } from "../hooks/useBienAsegurado";
import { usePago } from "../hooks/usePago";
import { useObservacion } from "../hooks/useObservacion";
import { useLoading } from "../hooks/useLoading"
import { useSocket } from "./SocketProvider";

import { useAuthContext } from "./AuthContext";

import axiosRequest from "../utils/axiosConfig";

import dayjs from "dayjs"

import { useLocation } from "react-router-dom";
import { CUOTAS } from "../constants/emision";
import { getAutogestionArchivos, getDataAutogestion, getDataPagoAutogestion } from "../services/Autogestion";
import formatSelectData from "../utils/formatSelectData";
import { useAnulacion } from "../hooks/useAnulacion";
import sendFiles from "../utils/uploadFile";

const SolicitudContext = createContext()

const useSolicitudContext = () => {
    const wizard = useContext(SolicitudContext)
    return wizard
}

const SolicitudProvider = ({ children, solicitud, selectsData }) => {

    const socket = useSocket()
    const location = useLocation();
    const id_operacion = new URLSearchParams(location.search).get("operacion");
    const tipoSolicitud = new URLSearchParams(location.search).get("tipoSolicitud");

    // User Data
    const { authState } = useAuthContext()
    const { userInfo } = authState
    
    // Error
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    // Datos Asegurado - Step 1
    const [provincias, setProvincias] = useState([]);
    const [localidades, setLocalidades] = useState([]);
    const [profesiones, setProfesiones] = useState([]);
    const [estadosCivil, setEstadosCivil] = useState([]);
    const [nacionalidades, setNacionalidades] = useState([]);

    // Datos Compañia - Step 2
    const [companias, setCompanias] = useState([])
    const [productos, setProductos] = useState([])
    const [listaProductos, setListaProductos] = useState([])
    const [coberturas, setCoberturas] = useState([])
    const [vigencias, setVigencias] = useState([])
    const [cuotas, setCuotas] = useState(null)

    // Datos Bien Asegurado - Step 3
    const [campos, setCampos] = useState([])
    const [datosBienAseguradoForm, setDatosBienAseguradoForm] = useState({})

    // *** Custom Hooks *** //

    const { loading, setLoading } = useLoading()

    // Forms
    const endosoForm = useForm(null, endosoFormReglas, datosEndosoForm);
    const anulacionForm = useForm(null, datosAnulaciónReglas, datosAnulacionForm)
    const seguroForm = useForm(null, datosSeguroReglas, datosSeguroForm)
    const aseguradoForm = useForm(null, datosAseguradoReglas, datosAseguradoForm, { id_tipo_solicitud: tipoSolicitud })
    const pagoForm = useForm(null, datosPagoReglas, datosPagoForm)
    const observacionForm = useForm(null, datosObservacionReglas, datosObservacionForm)
    const bienAseguradoForm = useForm(null, validacionesBienAsegurado, datosBienAseguradoForm, { campos: campos, seguro: seguroForm.inputValues })

    // Steps
    const { postearAseguradoForm, editarAseguradoForm } = useAsegurado()
    const { postearSeguroForm, editarSeguroForm } = useSeguro()
    const { postearBienAseguradoForm, editarBienAseguradoForm } = useBienAsegurado()
    const { postearPagoForm, editarPagoForm } = usePago()
    const { postearObservacionForm, editarObservacionForm } = useObservacion()
    const { postearAnulacion, editarAnulacion } = useAnulacion()

    const eliminarSolicitudParcial = async (id_asegurado, seguroId, pagoId, solicitudId) => {
        try {
            await eliminarBienAsegurado(solicitudId)
            await eliminarSolicitud(solicitudId)
            await eliminarPago(pagoId)
            await eliminarSeguro(seguroId)
            await eliminarAsegurado(id_asegurado)
        } catch (error) {
            console.log(error)
        }
    }

    const postearEndoso = async (endoso, solicitudID) => {
        const filtrarArchivos = Object.entries(endoso).filter(
            ([key, value]) => key.includes("archivo") && value !== ""
        );

        let endosoData = {
            id_compania: endoso.id_compania,
            numero_poliza: endoso.numero_poliza,
            id_solicitud: solicitudID,
            cambios_poliza: endoso.cambios_poliza,
        };

        await Promise.all(filtrarArchivos.map(async (archivo) => {
            console.log(archivo)
            const key = archivo[0]
            const value = archivo[1]
            const file = await sendFiles(value);
            console.log(file)
            endosoData[key] = file
        }))

        console.log(endosoData)

        try {
          const endoso = await axiosRequest.post("/endoso_solicitudes", endosoData);
          return endoso;
        } catch (error) {
          console.log(error);
        }
      };

    const editarEndosoForm = async (values, endoso) => {
        const filtrarArchivos = Object.entries(endoso).filter(
            ([key, value]) => key.includes("archivo") && value !== ""
        );

        let endosoData = {
            id_compania: values.id_compania,
            numero_poliza: values.numero_poliza,
            id_solicitud: values.id_solicitud,
            cambios_poliza: values.cambios_poliza,
            archivo_riesgo: values.archivo_riesgo,
            archivo_dni: values.archivo_dni,
            archivo_documentacion_importante: values.archivo_documentacion_importante,
            poliza: values.poliza
        };

        await Promise.all(filtrarArchivos.map(async (archivo) => {
            if(values[archivo[0]] !== endoso[archivo[0]]){
                const key = archivo[0]
                const value = values[archivo[0]]
                const file = await sendFiles(value);
                endosoData[key] = file
            }
        }))

        try {
          const data = await axiosRequest.put(`/endoso_solicitudes/${endoso.id_endoso_solicitud}`, endosoData);
          return data;
        } catch (error) {
          console.log(error);
        }
    }

    const postearEndosoSolicitud = async () => {
        try {
            let asegurado = null
            let aseguradoAdicionalId = null
            let seguro = null
            const checkAsegurado = await getAseguradoByDNI(aseguradoForm.inputValues.dni_asegurado)
            if(checkAsegurado){
                const aseguradoOriginal = checkAsegurado
                const aseguradoNuevo = aseguradoForm.inputValues
                aseguradoOriginal.nacimiento_asegurado = dayjs(aseguradoOriginal.nacimiento_asegurado).format("YYYY-MM-DD")
                const cambioAsegurado = Object.keys(aseguradoOriginal).every(key => aseguradoOriginal[key] === aseguradoNuevo[key])
                if(!cambioAsegurado){
                    const aseguradoAdicional = await postAseguradoAdicionalData(aseguradoNuevo)
                    aseguradoAdicionalId = aseguradoAdicional.id_asegurado_adicional
                }
                asegurado = checkAsegurado
            } else {
                asegurado = await postearAseguradoForm(aseguradoForm.inputValues)
            }
            seguro = await postearSeguroForm({ cia_cobertura_asegurada: endosoForm.inputValues.id_compania })
            const solicitudData = {
                id_asegurado: asegurado.id_asegurado, 
                id_asegurado_adicional: aseguradoAdicionalId,
                id_dato_facturacion: null,
                vendedor_solicitud: userInfo.id_operador,
                operador_solicitud: 66,
                operacion_solicitud: id_operacion,
                ingreso_solicitud: dayjs(new Date()).format("DD/MM/YYYY HH:mm:ss"),
                id_estado: 1,
                id_cobertura_asegurada: seguro.coberturaID,
                rechazo_solicitud: null,
                id_motivo: null,
                premio: 0,
                prima: 0,
                id_tipo_solicitud: tipoSolicitud,
                prioridad: observacionForm.inputValues.prioridad
              };
            solicitud = await postSolicitud(solicitudData)
            await postearObservacionForm(observacionForm.inputValues, solicitud.solicitudID, userInfo.id_operador, tipoSolicitud)
            await postearEndoso(endosoForm.inputValues, solicitud.solicitudID)
    
            const tipoSolicitudMensaje = {
                1: "Nueva solicitud creada",
                2: "Anulación por reemplazado creada",
                3: "Endoso creado"
            }

            const notificacion = {
                url_pagina: `/Emision/${solicitud.solicitudID}`,
                nombre_icono: "emision",
                nombre_pagina: "Solicitud Emisión",
                notificacion: `${tipoSolicitudMensaje[tipoSolicitud]} - ID: ${solicitud.solicitudID} - Fecha de ingreso: ${solicitudData.ingreso_solicitud} - Asegurado: ${aseguradoForm.inputValues.nombre_asegurado} ${aseguradoForm.inputValues.apellido_asegurado} - DNI: ${aseguradoForm.inputValues.dni_asegurado} - Vendedor: ${userInfo.nombre_operador} `,
                time: dayjs(new Date()).format("HH:mm"),
                roles: [1, 7, 14],
              }

            socket.emit('notificacion-crm-solicitud-creada', notificacion)
            await axiosRequest.post('/notificacion/crear', notificacion)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
            setError(true)
            setErrorMessage("Error al cargar solicitud de emisión.")
        }
    }

    const editarEndosoSolicitud = async () => {
        try {
            await editarAseguradoForm(aseguradoForm.inputValues, solicitud.solicitud.id_asegurado, solicitud.solicitud.id_asegurado_adicional)
            await editarSeguroForm({ cia_cobertura_asegurada: endosoForm.inputValues.id_compania }, solicitud.solicitud.id_cobertura_asegurada)
            await editarObservacionForm(observacionForm.inputValues, solicitud.solicitud, userInfo.id_operador)
            await editarEndosoForm(endosoForm.inputValues, solicitud.endoso)

            const solicitudData = { ...solicitud.solicitud, prioridad: observacionForm.inputValues.prioridad, id_estado: 1, id_estado_previo: solicitud.solicitud.id_estado, ingreso_solicitud: dayjs(new Date(solicitud.solicitud.ingreso_solicitud)).format("DD/MM/YYYY HH:mm:ss"), rechazo_solicitud: dayjs(new Date(solicitud.solicitud.rechazo_solicitud)).format("DD/MM/YYYY HH:mm:ss")}
            await actualizarSolicitud(solicitud.solicitud.id, solicitudData)

            const tipoSolicitudMensaje = {
                1: "Nueva solicitud editada",
                2: "Anulación por reemplazado editada",
                3: "Endoso editado"
            }

            const operadores = await axiosRequest.get('/operadores_usuarios')
            const operador = operadores.data.find(op => op.id_operador === solicitud.solicitud.operador_solicitud)

            const notificacion = {
                url_pagina: `/Emision/${solicitud.solicitud.id}`,
                nombre_icono: "emision",
                nombre_pagina: "Solicitud Emisión",
                notificacion: `${tipoSolicitudMensaje[tipoSolicitud]} - ID: ${solicitud.solicitud.id} - Fecha de ingreso: ${dayjs(solicitud.solicitud.ingreso_solicitud).format("DD/MM/YYYY HH:MM")} - Asegurado: ${aseguradoForm.inputValues.nombre_asegurado} ${aseguradoForm.inputValues.apellido_asegurado} - Vendedor: ${userInfo.nombre_operador} ${aseguradoForm.inputValues.dni_asegurado ? `- DNI: ${aseguradoForm.inputValues.dni_asegurado}` : `- CUIT/CUIL: ${aseguradoForm.inputValues.cuit_asegurado}`}`,
                time: dayjs(new Date()).format("HH:mm"),
                id_usuario: operador.id_usuario,
              }
            socket.emit('notificacion-crm-solicitud-creada', notificacion)
            await axiosRequest.post('/notificacion/crear', notificacion)

            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
            setError(true)
            setErrorMessage("Error al cargar solicitud de emisión.")
        }
    }
 
    const postear = async () => {

        let asegurado = null
        let aseguradoAdicionalId = null
        let seguro = null
        let pago = null
        let solicitud = null
        let solicitudData = null

        try {
            const checkAsegurado = await getAseguradoByDNI(aseguradoForm.inputValues.dni_asegurado)
            if(checkAsegurado){
                const aseguradoOriginal = checkAsegurado
                const aseguradoNuevo = aseguradoForm.inputValues
                aseguradoOriginal.nacimiento_asegurado = dayjs(aseguradoOriginal.nacimiento_asegurado).format("YYYY-MM-DD")
                const cambioAsegurado = Object.keys(aseguradoOriginal).every(key => aseguradoOriginal[key] === aseguradoNuevo[key])
                if(!cambioAsegurado){
                    const aseguradoAdicional = await postAseguradoAdicionalData(aseguradoNuevo)
                    aseguradoAdicionalId = aseguradoAdicional.id_asegurado_adicional
                }
                asegurado = checkAsegurado
            } else {
                asegurado = await postearAseguradoForm(aseguradoForm.inputValues)
            }
            seguro = await postearSeguroForm(seguroForm.inputValues)
            pago = await postearPagoForm(pagoForm.inputValues, asegurado.id_asegurado)

            solicitudData = {
                id_asegurado: asegurado.id_asegurado, 
                id_asegurado_adicional: aseguradoAdicionalId,
                id_dato_facturacion: pago.facturacionID, 
                vendedor_solicitud: userInfo.id_operador,
                operador_solicitud: 66, 
                operacion_solicitud: id_operacion,
                ingreso_solicitud: dayjs(new Date()).format("DD/MM/YYYY HH:mm:ss"),
                id_estado: 1,
                id_cobertura_asegurada: seguro.coberturaID,
                rechazo_solicitud: null,
                id_motivo: null,
                premio: 0,
                prima: 0,
                id_tipo_solicitud: tipoSolicitud,
                prioridad: observacionForm.inputValues.prioridad
            }
            solicitud = await postSolicitud(solicitudData)

            await postearBienAseguradoForm(bienAseguradoForm.inputValues, campos, solicitud.solicitudID)
            await postearObservacionForm(observacionForm.inputValues, solicitud.solicitudID, userInfo.id_operador, tipoSolicitud)

            if(tipoSolicitud == 2){
                await postearAnulacion(anulacionForm.inputValues, solicitud.solicitudID)
            }

            const tipoSolicitudMensaje = {
                1: "Nueva solicitud creada",
                2: "Anulación por reemplazado creada",
                3: "Endoso creado"
            }

            const notificacion = {
                url_pagina: `/Emision/${solicitud.solicitudID}`,
                nombre_icono: "emision",
                nombre_pagina: "Solicitud Emisión",
                notificacion: `${tipoSolicitudMensaje[tipoSolicitud]} - ID: ${solicitud.solicitudID} - Fecha de ingreso: ${solicitudData.ingreso_solicitud} - Asegurado: ${aseguradoForm.inputValues.nombre_asegurado} ${aseguradoForm.inputValues.apellido_asegurado} - Vendedor: ${userInfo.nombre_operador} ${aseguradoForm.inputValues.dni_asegurado ? `- DNI: ${aseguradoForm.inputValues.dni_asegurado}` : `- CUIT/CUIL: ${aseguradoForm.inputValues.cuit_asegurado}`}`,
                time: dayjs(new Date()).format("HH:mm"),
                roles: [1, 7, 14],
              }
            socket.emit('notificacion-crm-solicitud-creada', notificacion)
            await axiosRequest.post('/notificacion/crear', notificacion)

            setLoading(false)
        } catch (error) {
            console.log(error)
            const bodyEmail = `
            <html>
              <body>
                <h2>Error durante carga de solicitud de emisión</h2>
                <p>Mensaje de error</p>
                <code>${error?.message}</code>
                <hr />
                <p>Error stack</p>
                <code>${JSON.stringify(error.stack)}</code>
                <hr />
                <h4>Datos de la solicitud que se intentó cargar:</h4>
                ${solicitudData ?
                    `
                    <p>Solicitud:</p>
                    <code>${JSON.stringify(solicitudData)}</code>
                    <hr />
                    `
                : null}
                ${aseguradoForm.inputValues ?
                    `
                    <p>Asegurado:</p>
                    <code>${JSON.stringify(aseguradoForm.inputValues)}</code>
                    <hr />
                    `
                : null}
                ${seguroForm.inputValues ?
                    `
                    <p>Seguro:</p>
                    <code>${JSON.stringify(seguroForm.inputValues)}</code>
                    <hr />
                    `
                : null}
                ${pagoForm.inputValues ?
                    `
                    <p>Forma de pago:</p>
                    <code>${JSON.stringify(pagoForm.inputValues)}</code>
                    <hr />
                    `
                : null}
                ${bienAseguradoForm.inputValues ?
                    `
                    <p>Bien asegurado:</p>
                    <code>${JSON.stringify(bienAseguradoForm.inputValues)}</code>
                    <hr />
                    `
                : null}
              </body>
            </html>
          `
            const errorEmail = {
                mail: ["rodriandrade1498@gmail.com", "natalia.seguroweb@gmail.com"],
                htmlContent: bodyEmail,
                subject: `Error durante carga de solicitud`,
                adjuntos: []
            }
            await axiosRequest.post('/send_email', errorEmail)
            setLoading(false)
            setError(true)
            setErrorMessage("Error al cargar solicitud de emisión.")
            eliminarSolicitudParcial(asegurado?.id_asegurado, seguro?.coberturaID, pago?.facturacionID, solicitud?.solicitudID)
        }
    }

    const editar = async (solicitud) => {
        try {
            await editarAseguradoForm(aseguradoForm.inputValues, solicitud.solicitud.id_asegurado, solicitud.solicitud.id_asegurado_adicional, solicitud.asegurado)
            await editarSeguroForm(seguroForm.inputValues, solicitud.solicitud.id_cobertura_asegurada)
            await editarBienAseguradoForm(aseguradoForm.inputValues, seguroForm.inputValues, solicitud.seguro, bienAseguradoForm.inputValues, solicitud.bienAseguradoInfo, campos, solicitud.solicitud)
            await editarPagoForm(solicitud.pago, pagoForm.inputValues)
            await editarObservacionForm(observacionForm.inputValues, solicitud.solicitud, userInfo.id_operador)
            await editarAnulacion(anulacionForm.inputValues, solicitud.anulacion)
            //await editarEndosoForm(endosoForm.inputValues, solicitud.endoso)

            const solicitudData = { ...solicitud.solicitud, prioridad: observacionForm.inputValues.prioridad, id_estado: 1, id_estado_previo: solicitud.solicitud.id_estado, ingreso_solicitud: dayjs(new Date(solicitud.solicitud.ingreso_solicitud)).format("DD/MM/YYYY HH:mm:ss"), rechazo_solicitud: dayjs(new Date(solicitud.solicitud.rechazo_solicitud)).format("DD/MM/YYYY HH:mm:ss")}
            await actualizarSolicitud(solicitud.solicitud.id, solicitudData)

            const tipoSolicitudMensaje = {
                1: "Nueva solicitud editada",
                2: "Anulación por reemplazado editada",
                3: "Endoso editado"
            }

            const operadores = await axiosRequest.get('/operadores_usuarios')
            const operador = operadores.data.find(op => op.id_operador === solicitud.solicitud.operador_solicitud)

            const notificacion = {
                url_pagina: `/Emision/${solicitud.solicitud.id}`,
                nombre_icono: "emision",
                nombre_pagina: "Solicitud Emisión",
                notificacion: `${tipoSolicitudMensaje[tipoSolicitud]} - ID: ${solicitud.solicitud.id} - Fecha de ingreso: ${dayjs(solicitud.solicitud.ingreso_solicitud).format("DD/MM/YYYY HH:MM")} - Asegurado: ${aseguradoForm.inputValues.nombre_asegurado} ${aseguradoForm.inputValues.apellido_asegurado} - Vendedor: ${userInfo.nombre_operador} ${aseguradoForm.inputValues.dni_asegurado ? `- DNI: ${aseguradoForm.inputValues.dni_asegurado}` : `- CUIT/CUIL: ${aseguradoForm.inputValues.cuit_asegurado}`}`,
                time: dayjs(new Date()).format("HH:mm"),
                id_usuario: operador.id_usuario,
              }
            socket.emit('notificacion-crm-solicitud-creada', notificacion)
            await axiosRequest.post('/notificacion/crear', notificacion)

            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
            setError(true)
            setErrorMessage("Error al cargar solicitud de emisión.")
        }
    }

    const postearSolicitud = async (e, pasoSiguiente, validatorLastStep, isSubmitDisabled) => {
        isSubmitDisabled(true)
        const validateForm = await validatorLastStep(e)
        if(validateForm){
            setLoading(true)
            setError(false)
            pasoSiguiente() 
            if(solicitud){
                if(tipoSolicitud != 3){
                    await editar(solicitud)
                } else {
                    await editarEndosoSolicitud()
                }
            } else {
                if(tipoSolicitud != 3){
                    await postear()
                } else {
                    await postearEndosoSolicitud()
                }
            }
        } else {
            isSubmitDisabled(false)
        }
    }

    /*
    const getSeguroFormSelectsData = async (values) => {
        try {
            console.log("VAMOOOOOOOOS")
            console.log(values)
            const productos = await getProductos(values.cia_cobertura_asegurada)
            setProductos(productos)
            const coberturasList = await getCoberturas(values.cia_cobertura_asegurada, values.producto_cobertura_asegurada)
            console.log(coberturasList)
            setCoberturas(coberturasList)

            console.log("ABOUT YOU NOOOOOOWO")
            console.log(coberturasList)
            console.log("NOW")
            console.log(values.cobertura_cobertura_asegurada)

            const coberturaInfo = coberturasList.find(cob => cob.value === values.cobertura_cobertura_asegurada)
            console.log("QUE ES COBERTURA INFO")
            console.log(coberturaInfo)
            const descripcion = await axiosRequest.get(`/coberturas_productos_descripcion/${values.cobertura_cobertura_asegurada}/${coberturaInfo.key}`);
            console.log("LITTLE BIRD")
            console.log(descripcion)

            const vigencias = await getVigencias(values.cia_cobertura_asegurada, values.producto_cobertura_asegurada, values.cobertura_cobertura_asegurada)
            setVigencias(vigencias)

            seguroForm.completeForm(values)
            seguroForm.handleChangeExternal('descripcion_cobertura_asegurada', descripcion.data.descripcion_cobertura_producto)
        } catch (error) {
            console.log(error)
        }
    }   
    */

    useEffect(() => {
        const getArchivos = async () => {
            if(id_operacion){
                try {
                    /*
                    const archivos = await getAutogestionArchivos(id_operacion)
                    const data = await getDataAutogestion(id_operacion)
                    const pago = await getDataPagoAutogestion(id_operacion)
                    if(archivos.length > 0){
                        let objArchivos = {}
                        archivos.forEach(archivo => {
                            objArchivos[archivo.nombre_campo] = archivo.url
                        })
                        bienAseguradoForm.completeForm({...objArchivos, ...data})
                        pagoForm.completeForm(pago.pago)
                        //getSeguroFormSelectsData(pago.seguro)
                    }
                    */
                } catch (error) {
                    console.log(error)
                }
            }
        }
        getArchivos()
    }, [id_operacion])

    useEffect(() => {
        if(solicitud && (Object.values(solicitud).every(value => Object.keys(value).length > 0))){
            solicitud.asegurado.nacimiento_asegurado = dayjs(solicitud.asegurado.nacimiento_asegurado).format("YYYY-MM-DD")
            aseguradoForm.completeForm(solicitud.asegurado)
            seguroForm.completeForm(solicitud.seguro)
            bienAseguradoForm.completeForm(solicitud.bienAsegurado)
            pagoForm.completeForm(solicitud.pago)
            observacionForm.completeForm({ prioridad: solicitud.solicitud.prioridad == 1 ? true : false })
            anulacionForm.completeForm(solicitud.anulacion)
            endosoForm.completeForm(solicitud.endoso)
            if(tipoSolicitud != 3){
                const productosList = formatSelectData(
                    selectsData.productos,
                    "id_producto",
                    "nombre_producto",
                    "id_producto"
                );
                setCompanias(selectsData.companias)
                setProductos(productosList)
                setListaProductos(selectsData.productos)
                setVigencias(selectsData.vigencias)
            }

        }
    }, [solicitud, selectsData])

    // Step 1
        // Traer lista de localidades en base a la provincia seleccionada
        useEffect(() => {
            if(aseguradoForm.inputValues.provincia_asegurado){
                const getLocalidadesSelect = async () => {
                    try {
                        const localidades = await getLocalidades(aseguradoForm.inputValues.provincia_asegurado);
                        setLocalidades(localidades)
                    } catch (error) {
                        console.log(error)
                    }
    
                }
                getLocalidadesSelect();
            }
        }, [aseguradoForm.inputValues.provincia_asegurado])

    // Companias Step 2
    useEffect(() => {
        const getDataAseguradaForm = async () => {
            try {
                const provincias = await getProvincias()
                const profesiones = getProfesiones()
                const estadosCivil = getEstadosCivil()
                const nacionalidades = getNacionalidades()
                setProvincias(provincias)
                setProfesiones(profesiones)
                setEstadosCivil(estadosCivil)
                setNacionalidades(nacionalidades)
            } catch (error) {
                console.log(error)
            }
        }
        getDataAseguradaForm()
    }, [])

    // Companias Step 2
    useEffect(() => {
        const getCompaniasEmision = async () => {
            try {
                const companias = await getCompanias()
                const vigencias = await getVigencias()
                const cuotas = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(cuota => {
                    return {
                        label: cuota,
                        value: cuota,
                        key: cuota
                    }
                })
                setCompanias(companias)
                setVigencias(vigencias)
                setCuotas(cuotas)
            } catch (error) {
                console.log(error)
            }
        }
        getCompaniasEmision()
    }, [])

    // Selects Step 2
    useEffect(() => {
        const updateSelectsData = async () => {
            if(tipoSolicitud != 3){
                const firstEmptyIndex = Object.values(seguroForm.inputValues).findIndex(value => value === '')
                switch(firstEmptyIndex){
                    case 1: 
                        const productos = await getProductos(seguroForm.inputValues.cia_cobertura_asegurada)
                        const productosList = formatSelectData(
                            productos,
                            "id_producto",
                            "nombre_producto",
                            "id_producto"
                        );
                        setProductos(productosList)
                        setListaProductos(productos)
                        break
                    case 2: 
                        console.log(seguroForm.inputValues.cia_cobertura_asegurada)
                        console.log(seguroForm.inputValues.producto_cobertura_asegurada)
                        //const coberturasList = await getCoberturas(seguroForm.inputValues.cia_cobertura_asegurada, seguroForm.inputValues.producto_cobertura_asegurada)
                    default: 
                        return
                }
            }
        }
        updateSelectsData()
    }, [seguroForm.inputValues])

    const iniciarBienAsegurado = (campos) => {
        // Valores iniciales para el estado de bien asegurado
        let camposNames = {}
        campos.forEach(campo => {
            const value = bienAseguradoForm.inputValues[campo.name_campo] ? bienAseguradoForm.inputValues[campo.name_campo] : ''
            //camposNames[campo.name_campo] = solicitud ? value : ''
            camposNames[campo.name_campo] = value ? value : ''
        })
        setDatosBienAseguradoForm(camposNames)
    }

    // Campos Step 3
    useEffect(() => {
        const getCampos = async () => {
            if(tipoSolicitud != 3){
                if(seguroForm.inputValues.cia_cobertura_asegurada && seguroForm.inputValues.producto_cobertura_asegurada && listaProductos.length > 0){
                    // Campos
                    const id_producto_cia = listaProductos.find(prod => seguroForm.inputValues.cia_cobertura_asegurada == prod.id_compania && seguroForm.inputValues.producto_cobertura_asegurada == prod.id_producto)?.id_producto_cia
                    const campos = await getCamposCobertura(id_producto_cia)
                    setCampos(campos)
                    
                    iniciarBienAsegurado(campos.campos)
                }
            }
        }
        getCampos()
    }, [seguroForm?.inputValues?.cia_cobertura_asegurada, seguroForm?.inputValues?.producto_cobertura_asegurada, listaProductos])

    let values = {}

    const nuevaSolicitud = {
        asegurado: {
            name: "Asegurado",
            values: aseguradoForm.inputValues,
            handleChange: aseguradoForm.handleChange,
            handleChangeExternal: aseguradoForm.handleChangeExternal,
            formErrors: aseguradoForm.formErrors,
            validator: aseguradoForm.handleValidation,
            data: {
                provincias: provincias,
                localidades: localidades,
                profesiones: profesiones,
                estadosCivil: estadosCivil,
                nacionalidades: nacionalidades,
            },
            resetForm: aseguradoForm.resetForm,
            completeForm: aseguradoForm.completeForm,
            completeField: aseguradoForm.completeField
        },
        seguro: {
            name: "Seguro",
            values: seguroForm.inputValues,
            handleChange: seguroForm.handleChange,
            formErrors: seguroForm.formErrors,
            validator: seguroForm.handleValidation,
            data: {
                companias: companias,
                productos: productos,
                coberturas: coberturas,
                vigencias: vigencias,
                cuotas: cuotas
            }
        },
        bienAsegurado: {
            name: "Bien Asegurado",
            values: bienAseguradoForm.inputValues,
            handleChange: bienAseguradoForm.handleChange,
            handleChangeExternal: bienAseguradoForm.handleChangeExternal,
            handleChangeCheckbox: bienAseguradoForm.handleChangeCheckbox,
            formErrors: bienAseguradoForm.formErrors,
            validator: bienAseguradoForm.handleValidation,
            data: {
                campos: campos,
            }
        },
        pago: {
            name: "Forma de pago",
            values: pagoForm.inputValues,
            handleChange: pagoForm.handleChange,
            formErrors: pagoForm.formErrors,
            validator: pagoForm.handleValidation
        },
        observacion: {
            name: "Observación",
            values: observacionForm.inputValues,
            handleChange: observacionForm.handleChange,
            handleChangeCheckbox: observacionForm.handleChangeCheckbox,
            formErrors: observacionForm.formErrors,
            validator: observacionForm.handleValidationAsync
        },
        solicitud: {
            name: "Enviar",
            postearSolicitud: postearSolicitud,
            posteada: false,
            loading: loading, 
            error: error,
            errorMessage: errorMessage
        }
    }

    if(tipoSolicitud == 1){
        values = nuevaSolicitud
    } else if(tipoSolicitud == 2){
        const anular = {
            name: "Anular",
            values: anulacionForm.inputValues,
            handleChange: anulacionForm.handleChange,
            handleChangeExternal: anulacionForm.handleChangeExternal,
            formErrors: anulacionForm.formErrors,
            validator: anulacionForm.handleValidation,
            data: {
                companias: companias,
            }
        }
        values["anular"] = anular
        values = {...values, ...nuevaSolicitud}
    } else if(tipoSolicitud == 3){
            const endoso = {
                name: "Endoso",
                values: endosoForm.inputValues,
                handleChange: endosoForm.handleChange,
                handleChangeExternal: endosoForm.handleChangeExternal,
                formErrors: endosoForm.formErrors,
                validator: endosoForm.handleValidation,
                data: {
                    companias: companias,
                }
            }
            values = { asegurado: nuevaSolicitud.asegurado, endoso: endoso, observacion: nuevaSolicitud.observacion, solicitud: nuevaSolicitud.solicitud }
        
    }

    console.log(values)

    return (
        <SolicitudContext.Provider value={values}>
            {children}
        </SolicitudContext.Provider>
    )
}

export { SolicitudProvider, useSolicitudContext }

