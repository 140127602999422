import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Container = styled.div`
  table {
    //width: 100%;
    border-collapse: collapse;
    background-color: ${({ theme }) => theme.background};
    margin-bottom: 40px;
    transition: all 0.5s;
    max-width: 1190px;
    min-width: 1190px;
    margin: 0 auto;
  }

  table th {
    text-align: left;
    border-collapse: collapse;
    font-size: 14px;
    color: ${({ theme }) => theme.table_header_text};
    font-weight: 400;
    padding: 20px 0;
    border-top: 1px solid ${({ theme }) => theme.border};
    font-weight: 500;
    background-color: ${({ theme }) => theme.table_header};
    position: sticky;
    top: 0px;
    border-bottom: 1px solid ${({ theme }) => theme.border};
    z-index: 10;
    cursor: pointer;
    transition: all 0.5s;
  }

  table tr {
    border-collapse: collapse;
    border-bottom: 1px solid ${({ theme }) => theme.border};
    transition: all 0.5s;
  }

  table td {
    font-size: 14px;
    color: ${({ theme }) => theme.subtitle};
    font-weight: 400;
    padding: 20px 0;
    border-collapse: collapse;
    transition: all 0.5s;
  }

  td .whatsapp-telefono {
    display: flex;
    align-items: center;
    gap: 4px;
    text-decoration: none;
    color: ${leerColor(colores.verde)};
    font-weight: 500;
  }

  .selected {
    background-color: ${({ theme }) => theme.selected_table};
  }

  table td img {
    width: 85px;
  }

  /*
  .compania img {
    //width: 100px;
    box-sizing: border-box;
    padding: 4px;
    border-radius: 6px;
    background-color: #fff;
  }
    */

  .atm, .hdi {
    width: 60px;
    box-sizing: border-box;
    padding: 4px;
    border-radius: 6px;
    background-color: #fff;
  }

  .experta {
    width: 75px;
    box-sizing: border-box;
    padding: 4px;
    border-radius: 6px;
    background-color: #fff;
  }

  .cliente, .vendedor, .operador, .cobertura, .producto, .estado {
    padding-right: 12px;
    box-sizing: border-box;
  }

  .compania img {
    padding: 4px;
    border-radius: 6px;
    background-color: #fff;
  }

  .cliente a {
    text-decoration: none;
    color: ${leerColor(colores.grisEstados)};
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid rgb(234, 234, 234);
  }

  .avatar_small {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    border: ${({ theme }) => `1px solid ${theme.border}`};
    transition: all 0.5s;
  }

  .vendedor_operador {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .userIcon {
    display: flex;
    border: 1px solid #eaeaea;
    font-size: 20px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 100px;
    color: #1a56ff;
  }

.asegurado {
    font-weight: 600 !important;
    display: flex;
    align-items: center;
    gap: 10px;
}

.aseguradoInfo {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.aseguradoAdditionalInfo {
    font-weight: 300;
    display: flex;
    align-items: center;
    gap: 4px;
}

/*
  .pendiente, .pendiente-emisión {
    display: flex !important;
    font-weight: 600 !important;
    background-color: rgb(246, 246, 246) !important;
    color: rgb(120, 120, 120) !important;
    border-radius: 20px !important;
    padding: 6px 12px !important;
    position: relative !important;
    width: fit-content !important;
    align-items: center !important;
  }
    */

  .estado-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
    position: relative;
    width: fit-content;
  }

  .prioridad {
    background-color: ${leerColor(colores.negro)};
    color: ${leerColor(colores.blanco)};
    width: fit-content;
    border-radius: 8px;
    padding: 4px 10px;
    box-sizing: border-box;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 10.5px;
    position: absolute;
    right: -5px;
    top: -17px;
  }

    // Estados //
    .emisión-sin-curso, .emitido---cambio-pas, .endoso-enviado-a-compañia {
      font-weight: 600;
      background-color: ${({ theme }) => theme.celeste_fondo};
      color: ${({ theme }) => theme.celeste_texto};
      border-radius: 20px;
      padding: 6px 12px;
      display: block;
      transition: all 0.5s;
    }

  // Estados //
  .emitido,
  .cerradopositivo,
  .cerradopositivo-conformeparcial,
  .cerradopositivo-conforme,
  .cerradopositivo-disconforme,
  .resueltopositivo,
  .resuelto-positivo,
  .emitido-certificado {
    font-weight: 600;
    background-color: ${({ theme }) => theme.verde_fondo};
    color: ${({ theme }) => theme.verde_texto};
    border-radius: 20px;
    padding: 6px 12px;
    width: min-content;
    display: block;
    transition: all 0.5s;
    position:relative;
  }

  .veces_notificado {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 600;
    font-size: 12px;
    background-color: #EEE;
    color: #222;
    border-radius: 20px;
    padding: 6px 12px;
    width: min-content;
  }

  .pendiente-emisión-sin-asignar {
    /*
    font-weight: 600;
    background-color: #fff7ed;
    color: ${leerColor(colores.naranjaEstados)};
    border-radius: 20px;
    padding: 6px 12px;
    position: relative;
    //border:1px solid ${leerColor(colores.naranjaEstados)};
    width: min-content;
    */

    display: flex !important;
    font-weight: 600 !important;
    background-color: ${({ theme }) => theme.naranja_fondo};
    color: ${leerColor(colores.naranjaEstados)};
    border-radius: 20px !important;
    padding: 6px 12px !important;
    position: relative !important;
    width: fit-content !important;
    align-items: center !important;
    transition: all 0.5s;
  }
  .pendiente,
  .ingresado,
  .pendiente-emisión {
    display: flex !important;
    font-weight: 600 !important;
    background-color: ${({ theme }) => theme.gris_fondo} !important;
    color: ${({ theme }) => theme.gris_texto} !important;
    border-radius: 20px !important;
    padding: 6px 12px !important;
    position: relative !important;
    width: fit-content !important;
    align-items: center !important;
  }
  .rechazado,
  .rechazado-sin-asignar,
  .negativoderivado,
  .negativo-derivado,
  .negativo-cerrado,
  .cerradonegativo {
    font-weight: 600;
    background-color: ${({ theme }) => theme.rojo_fondo};
    color: ${leerColor(colores.rojo)};
    border-radius: 20px;
    padding: 6px 12px;
    //border:1px solid ${leerColor(colores.rojo)};
    width: min-content;
    display: block;
    transition: all 0.5s;
    position: relative;
  }

  .emisiónprovisoria, .pendientededocumentación, .pendientedepago, .pendientederesolución, .promesa-de-pago {
    font-weight: 600;
    background-color: #f0faff;
    color: ${leerColor(colores.celeste)};
    border-radius: 20px;
    padding: 6px 12px;
    text-align: left;
    width: min-content;
    display: block;
  }
  .pendientesuscripción {
    color: ${leerColor(colores.naranjaEstadosSuscripcion)};
    font-weight: 700;
    display: block;
  }
  .pendienteaprobación, .ofrecimiento, .notificado, .contactado{
    font-weight: 600;
    background-color: #ecf0ff;
    color: ${leerColor(colores.azul)};
    border-radius: 20px;
    padding: 6px 12px;
    width: min-content;
    display: block;
  }
  .estado_previo_rechazado {
    position: absolute;
    top: -15px;
    right: 0;
    //background: #fff7ed;
    background: ${leerColor(colores.blanco)};
    border-radius: 100px;
    color: hsl(36.10000000000002,100%,66.1%);
    padding: 4px;
    box-sizing: border-box;
    display: flex;
    border: 1px solid hsl(36.1deg 100% 82.49%);
  }

  .promesa-de-pago {
    font-weight: 600;
    background-color: ${leerColor(colores.naranjaNot)};
    color: ${leerColor(colores.naranja)};
    border-radius: 20px;
    padding: 6px 12px;
    text-align: left;
    width: min-content;
    display: block;
    position:relative;
  }

  .arrastre {
    position: absolute;
    top: -14px;
    right: -10px;
    font-size: 8px;
    font-weight: 600;
    background-color: ${leerColor(colores.celeste)};
    color: #EEE;
    padding: 4px;
    border-radius: 8px;
  }

  .actionsContainer {
    display: flex;
    gap: 16px;
    box-sizing: border-box;
     
    a { 
      text-decoration: none;
    }
  }

  .actions-center {
    justify-content: center;
  }

  .container-flex {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .activo{
    font-weight: 600;
    background-color: ${({ theme }) => theme.verde_fondo};
    color: ${({ theme }) => theme.verde_texto};
    border-radius: 20px;
    padding: 6px 12px;
  }

  .inactivo{
    font-weight: 600;
    background-color: ${({ theme }) => theme.gris_fondo};
    color: ${({ theme }) => theme.gris_texto};
    border-radius: 20px;
    padding: 6px 12px;
  }

  .notificado, .contactado {
    background-color: #faf4ff;
    color: ${leerColor(colores.violeta)};
  }
`;

export const SinResultadosContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  svg {
    padding: 50px 0 20px;
  }
  h3 {
    font-size: 18px;
    font-weight: 600;
    color: ${leerColor(colores.grisEstados)};
  }
`;
