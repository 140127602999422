export const cargarSiniestrosForm = {
  dni: '',
  fecha_siniestro: '',
  num_siniestro: '',
  poliza: '',
  producto: '',
  tipo_siniestro: '',
  informe: '',
  adjuntos: ''
};

export const editarSiniestrosForm = {
  dni: '',
  fecha_siniestro: '',
  num_siniestro: '',
  poliza: '',
  producto: '',
  tipo_siniestro: '',
  informe: '',
  adjuntos: ''
};

// *** EMISIÓN *** //

// Cliente
export const datosAnulacionForm = {
  //poliza: "",
  id_compania: "",
  numero_poliza: ""
};


// Cliente
export const datosAseguradoForm = {
  nombre_asegurado: "",
  apellido_asegurado: "",
  nacimiento_asegurado: null,
  dni_asegurado: "",
  cuit_asegurado: "",
  email_asegurado: "",
  telefono_asegurado: "",
  codigo_area_asegurado: "",
  condicion_fiscal_asegurado: "",
  provincia_asegurado: "",
  localidad_asegurado: "",
  direccion_calle_asegurado: "",
  direccion_numero_asegurado: "",
  direccion_piso_asegurado: "",
  direccion_dpto_asegurado: "",
  codigo_postal_asegurado: "",
  genero_asegurado: "",
  profesion_asegurado: "",
  estado_civil_asegurado: "",
  nacionalidad_asegurado: "ARGENTINA",
  lugar_nacimiento_asegurado: "",
  tipo_persona_asegurada: "",
  archivo_estatuto_empresa_asegurado: ""
};

// Compañia
export const datosSeguroForm = {
  cia_cobertura_asegurada: '',
  producto_cobertura_asegurada: '',
  cobertura_cobertura_asegurada: '',
  valor_cobertura_asegurada: '',
  vigencia_cobertura_asegurada: '',
  tipo_facturacion_cobertura_asegurada: '',
  cuotas_cobertura_asegurada: '',
};

// Pago
export const datosPagoForm = {
  medio: "",
  num_dato_facturacion: "",
  vencimiento_dato_facturacion: "",
  titular_dato_facturacion: "",
  dni_dato_facturacion: "",
  banco_dato_facturacion: "",
  marca_dato_facturacion: "",
  cuil_titular_dato_facturacion: ""
};

// Observacion
export const datosObservacionForm = {
  observaciones: "",
  prioridad: false
};

// Operación (Carga Solicitud)
export const operacionSolicitudForm = {
  num_operacion: "",
  tipo_solicitud: ""
};


// Observacion
export const modalRechazoForm = {
  motivo: "",
  mensaje: "",
  archivos: ""
};

// Observacion
export const modalEstadoEmisionForm = {
  estado: "",
  mensaje: ""
};

// Usuario
export const usuarioForm = {
  id_operador:'',
  nombre_operador:'',
  apellido_operador:'',
  nacimiento_operador:'',
  email_operador:'',
  telefono_operador:'',
  telefono_b_operador:'',
  direccion_calle_operador: '',
  direccion_numero_operador: '',
  direccion_piso_operador: '',
  direccion_dpto_operador: '',
  codigo_postal_operador: '',
  provincia_operador:'',
  localidad_operador: '',
  condicion_operador: '',
  dni_operador:'',
  cuit_operador:'',
  id_usuario:'',
  usuario_usuario:'',
  clave_usuario:'',
  id_rol:'',
  interno_usuario:'',
  whatsapp_usuario:'',
  siniestros_habilitado_usuario: '',
  total: false, 
  parcial: false,
  estado_usuario:'',
};

// Generar clave
export const generarClaveForm = {
  clave_usuario: '',
  clave_usuario_repetida: '',
};

// Login
export const loginForm = {
  usuario: '',
  password: '',
};

// Objetivos Leads
export const objetivoLeadForm = {
  mes: '',
  anio: '',
  canal: '',
  leads: '',
  operaciones: '',
  producto: ''
};

// Detalles Plan Landing
export const nuevoDetalleForm = {
  detalle: ''
};

// Beneficios Plan Landing
export const nuevoBeneficioForm = {
  beneficio: ''
};

// Crear plan
export const crearPlanForm = {
  landing: '',
  plan: '',
  producto: '',
  compania: '',
  coberturas: false,
  valor_uno: null,
  valor_dos: null,
  operador_uno: null,
  operador_dos: null,
  detalles: [],
  beneficios: [],
  limite: ''
};

// Crear plan
export const polizaForm = {
  tipo: 'poliza',
  certificado: '',
  poliza: '',
  numero_poliza: '',
  premio: ''
};

// Crear plan
export const datosEndosoForm = {
  numero_poliza: "",
  id_compania: "",
  cambios_poliza: "",
  archivo_dni: "",
  archivo_riesgo: ""
};

// Crear plan
export const crearGrupoForm = {
  nombre: "",
  integrantes: ""
};

// Editar plan
export const editarGrupoForm = {
  integrantes: "",
};

// Crear plan
export const objetivosForm = {
  operaciones: '',
  prima: ''
};

// Crear plan
export const objetivosPremiosCsvForm = {
  objetivos: '',
};

// Crear Excepcion
export const excepcionForm = {
  usuario: '',
  tipo: '',
  periodo: '',
  fecha_desde: '',
  fecha_hasta: '',
  fecha: '',
  hora: '',
  hora_desde: '',
  hora_hasta: ''
};

// Crear Excepcion
export const fichajePorUsuarioForm = {
  usuario: '',
  fecha: '',
  hora: '',
  fichaje: ''
};

// Crear Excepcion
export const fichajePropioForm = {
  fichaje: ''
};

// Crear Excepcion
export const gestionarRechazoForm = {
  estado: '',
  medio_contacto: '',
  mensaje: '',
  adjunto: ''
};

export const subirRechazoManualForm = {
  importe: '',
  motivo: '',
  comentario: '',
  poliza: '',
  id_compania: ''
};


export const subirRechazoManualUpdateForm = {
  idNuevoEstado: '',
  medioContacto: '',
};

// Crear plan
export const limitesPremiosForm = {
  limite_general: '',
  limite_objetivos: ''
};
