import React from "react";
import { Container } from "./styled";
import { FiClock } from "react-icons/fi";

const CardNumber = ({ title, value, background, icon }) => {
  return (
    <Container>
    <div className={`premio-container ${background && "total"}`}>
      <span>{title}</span>
      <div className="value-container">
        <h2>{value}</h2>
        {icon ? <span className="icon">{icon}</span> : null}
      </div>
    </div>
    </Container>
  );
};

export default CardNumber;
