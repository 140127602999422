// Styles
import { Container, Section } from "./styled";
// Components
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Grid, Col } from "../../../components/Grid";
import TitlePageSection from "../../../components/TitlePageSection";
import Subtitulo from "../../../components/Subtitulo";
import TabsInfo from "../../../components/TabsInfo";
import Sidebar from "../../../components/Sidebar";
import SpinnerLoading from "../../../components/SpinnerLoading";
import PlanCard from "../../../components/PlanCard";
import CotizadorForm from "../../../components/LandingsForms/CotizadorForm";
import Button from "../../../components/Button";
// Hooks
import { useTabs } from "../../../hooks/useTabs";
import { usePlanes } from "./hooks/usePlanes";
import { useDragAndDrop } from "./hooks/useDragAndDrop";
// Constants
import { landingsTabs } from "../../../constants/tabs";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
// Icons
import { FiDollarSign, FiFileText, FiBarChart2 } from "react-icons/fi";
import SectionLandings from "../../../components/SectionLandings";
import LimitesForm from "../../../components/LandingsForms/LimitesForm";
import NewWrapper from "../../../components/NewWrapper";
import NewInner from "../../../components/NewInner";
import Layout from "../../../components/Layout";
import NewTitlePageSection from "../../../components/NewTitlePageSection";
import NewButton from "../../../components/NewButton";
import NewTabs from "../../../components/NewTabs";
import Subtitle from "../../../components/Subtitle";
import { cotizadorLandingsTable, limitesLandingsTable } from "../../../utils/tableData";
import NewTable from "../../../components/NewTable";
import { useTable } from "../../../hooks/useTable";
import Divider from "../../../components/Divider";

const LandingsRefactor = () => {
  const { tabSelected, handleTab } = useTabs();
  const landing = landingsTabs[tabSelected];
  const {
    planes,
    handlePlanes,
    productos,
    limites,
    productoSeleccionado,
    pruductoSeleccionadoLimites,
    handleProducto,
    handleProductoLimite,
    loading,
    loadingText,
  } = usePlanes(landing);
  const { onDragOver, handleDrop, handleDraggingPlan } = useDragAndDrop(
    planes,
    handlePlanes
  );

  const { order, handleTableOrder } = useTable();

  console.log(limites);

  const tableCotizador = cotizadorLandingsTable();
  const tableLimites = limitesLandingsTable();

  return (
    <>
      {/* <Sidebar active="landings" /> */}

      <Layout>
        <Container>
          <NewWrapper>
            <NewInner>
              <Grid colGap={21} rowGap={10} narrow={false}>
                <Col desktop={12}>
                  <NewTitlePageSection
                    title="Landings"
                    description="Modificá los valores de cotización para los planes de las
                  landings de Seguro Web"
                  >
                    <Link to={"/Landings/Plan/Crear"}>
                      <NewButton backgroundColor={true}>Crear Plan</NewButton>
                    </Link>
                  </NewTitlePageSection>
                </Col>

                <Col desktop={12}>
                  <NewTabs
                    tabs={landingsTabs}
                    tabSelected={tabSelected}
                    handleTab={handleTab}
                  />
                </Col>
              </Grid>

              <Grid colGap={21} rowGap={10} narrow={false}>
                <Col desktop={6}>
                  <Subtitle
                    icon={<FiDollarSign />}
                    title={`Cotizador`}
                    description={`Valor máximo para cotizar cada producto`}
                    disableDivider={true}
                  />
                  <div className="table-container">
                  <NewTable
                    columns={tableCotizador}
                    data={productos}
                    loading={loading}
                    order={handleTableOrder}
                    orderParams={order}
                  />
                  </div>
                </Col>

                <Col desktop={6}>
                  <Subtitle
                    icon={<FiDollarSign />}
                    title={`Límites`}
                    description={`Valores límite para cada aseguradora y producto`}
                    disableDivider={true}
                  />
                  <div className="table-container">
                  <NewTable
                    columns={tableLimites}
                    data={limites}
                    loading={loading}
                    order={handleTableOrder}
                    orderParams={order}
                  />
                  </div>

                </Col>
              </Grid>

              <Divider />

              <Grid colGap={21} rowGap={10} narrow={false}>
                <Col desktop={12}>
                  <Subtitle
                    icon={<FiFileText />}
                    title={`Planes`}
                    description={`Lista de planes preparados para la landing`}
                    disableDivider={true}
                  />
                </Col>
                <Col desktop={12}>
                  <NewTabs
                    tabs={productos.map(item => {
                      return {
                        name: item.descripcion
                      }
                    })}
                    tabSelected={tabSelected}
                    handleTab={handleTab}
                  />
                </Col>
                <Col desktop={12}>

                <div className="planes-container">
                  {planes.map((plan, index) => {
                    return (
                      <>
                        <PlanCard
                          data={plan}
                          key={plan.id}
                          active={plan.estado === 1}
                          onDrop={handleDrop}
                          onDragOver={onDragOver}
                          handleDraggingPlan={handleDraggingPlan}
                        />
                      </>
                    );
                  })}
                </div>
                </Col>

                <Col desktop={12}>
                {loading ? <SpinnerLoading text={loadingText} /> : null}
              </Col>
              </Grid>

              {/* {landing.name === "Seguro Bici" ? (
                <>
                <Subtitle
                icon={<FiDollarSign />}
                title={`Cotizador`}
                description={`Establecé el valor máximo del cotizador de ${landing.name} para cada producto`}
                disableDivider={true}
                />
                <CotizadorForm productos={productos} />
                </>
              ) : null}

              {landing.name === "Seguro Bici" ? (
                <SectionLandings
                  columns={12}
                  icon={<FiBarChart2 />}
                  title="Limites"
                  description={`Establecé los valores límite para cada aseguradora y producto`}
                  divider={true}
                  noMargin={true}
                >
                  {productos.length > 0 && (
                    <>
                      <div className="productos-limites-container">
                        {productos.map((producto) => {
                          return (
                            <button
                              onClick={() =>
                                handleProductoLimite(producto.numero)
                              }
                              className={`${
                                pruductoSeleccionadoLimites ===
                                  producto.numero && "producto-seleccionado"
                              }`}
                            >
                              {producto.descripcion}
                            </button>
                          );
                        })}
                      </div>
                    </>
                  )}
                  <LimitesForm
                    limites={limites}
                    productoSeleccionado={pruductoSeleccionadoLimites}
                    productos={productos}
                  />
                </SectionLandings>
              ) : null}

              <SectionLandings
                columns={12}
                icon={<FiFileText />}
                title="Planes"
                description={`Establecé el valor máximo del cotizador de ${landing.name} para cada producto`}
                divider={landing.name === "Seguro Bici"}
                noMargin={true}
              >
                {landing.name === "Seguro Bici" ? (
                  <div className="productos-container">
                    {productos.length > 0 &&
                      productos.map((producto) => {
                        return (
                          <button
                            onClick={() => handleProducto(producto.numero)}
                            className={`${
                              productoSeleccionado === producto.numero &&
                              "producto-seleccionado"
                            }`}
                          >
                            {producto.descripcion}
                          </button>
                        );
                      })}
                  </div>
                ) : null}

                <div className="planes-container">
                  {planes.map((plan, index) => {
                    return (
                      <>
                        <PlanCard
                          data={plan}
                          key={plan.id}
                          active={plan.estado === 1}
                          onDrop={handleDrop}
                          onDragOver={onDragOver}
                          handleDraggingPlan={handleDraggingPlan}
                        />
                      </>
                    );
                  })}
                </div>
              </SectionLandings>

              <Col desktop={12}>
                {loading ? <SpinnerLoading text={loadingText} /> : null}
              </Col>
            </Grid> */}
            </NewInner>
          </NewWrapper>
        </Container>
      </Layout>
    </>
  );
};

export default LandingsRefactor;
